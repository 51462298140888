.text-green-400 {
  color: #68d391;
}

.text-red-400 {
  color: #fc8181;
}

.flex {
  display: flex;
}

.items-stretch {
  align-items: stretch;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
