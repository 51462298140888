@font-face {
    font-family: TestNational2Collection;
    src: url(./tn2c.woff);
    font-weight: normal;
}

@font-face {
    font-family: TestNational2Collection;
    src: url(./tn2cl.woff);
    font-weight: 300;
}

.item {
    color: #ef2e2a;
    font-family: TestNational2Collection;
    font-weight: normal;
}

.cal {
    font-family: TestNational2Collection;
    font-weight: 300;
    color: black;
}

.price {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: black;
}

.extra {
    font-family: TestNational2Collection;
    font-weight: 300;
    color: grey;
}

.extra2 {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: #ef2e2a;
}

.itembig {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: #ef2e2a;
}

.pricebig {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: black;
}

.calbig {
    font-family: TestNational2Collection;
    font-weight: 300;
    color: black;
}

.feeds {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: white;
}

.itemsmall {
    color: #ef2e2a;
    font-family: TestNational2Collection;
    font-weight: normal;
}

.calsmall {
    font-family: TestNational2Collection;
    font-weight: 300;
    color: grey;
}

.side {
    font-family: TestNational2Collection;
    font-weight: normal;
    color: grey;
}





#monitor-container{
    position: relative
}

.chalkboad-item {
    font-family: TestNational2Collection;
    font-size: 100px;
    font-weight: bolder;
    color: white;
}

.selected {
    background-color: yellow;
    color: black;
}