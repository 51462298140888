.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.w-full {
  width: 100%;
}

.max-w-512 {
  max-width: 512px;
}

.mx-8 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-4 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.mr-4 {
    margin-right: 0.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
