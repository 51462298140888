.set {
  overflow: hidden;
  padding: 40px;
  text-align: center;
}

.set .d-pad {
  margin-right: 40px;
}
.set .d-pad,
.o-pad {
  display: inline-block;
}
